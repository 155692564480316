<template>
    <div style="background:#fff">
        <div ref="gwn" class="crm-customer">
            <div class="crm-customer-search">
                <!-- <div @click.stop="showPicker = true" class="crm-customer-sub">{{columns[pickerIndex]}}</div> -->
                <van-search
                    v-model="keyword"
                    class="search"
                    show-action
                    placeholder="客户名称/手机号码"
                    >
                    <template #action >
                        <!-- <van-icon @click="toAdd" name="plus" size="24px" color="#00cca2"/> -->
                        <!-- <van-icon @click="filter_show=true" name="plus" size="24px" color="#00cca2"/> -->
                        <!-- <van-icon @click="filter_show=true" name="filter-o" size="24px"/> -->
                    </template>
                </van-search>
            </div>
            <van-sticky>
                <van-dropdown-menu active-color="#00DE93">
                    <van-dropdown-item v-model="filter_is_sub" :options="sub_option" @change="changeFilter" />
                    <van-dropdown-item v-model="filter_task_step" :options="option1" @change="changeFilter" />
                    <van-dropdown-item v-model="filter_sort_by" :options="option2" @change="changeFilter" />
                </van-dropdown-menu>
            </van-sticky>
            <div class="crm-customer-list">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="listFinishedText"
                    @load="onLoad"
                    >
                    <van-cell @click="toDetail(item)" is-link v-for="item in list" :key="item.task_id">
                        <template #title>
                            <div class="crm-customer-info">
                                <div @click.stop v-if="isCheck" style="margin-right:5px">
                                    <van-checkbox @click.stop="handleCheck(item)" checked-color="#07c160" v-model="item.checked"></van-checkbox>
                                </div>
                                <div @click.stop="isShowShare(item)">
                                    <avatar :username="item.customer_name" color="#fff" :size="45"></avatar>
                                </div>
                                <div>
                                    <div>{{item.customer_name}}<van-icon v-if="item.is_bind_qywx" name="https://i01.wedots.cn/2022/06/20/d91a26201e35220aa147b47aeef0b01f.png" size="16"/></div>
                                    <div>
                                        <van-tag round color="#f4f4f4">{{item.task_step_text}}</van-tag>
                                        <van-tag round color="#f4f4f4">
                                            <div style="max-width:100px" class="van-ellipsis">{{item.channel_id}}</div>
                                        </van-tag>
                                        <van-tag round color="#f4f4f4" v-if="item.course_cate_id">
                                            <div style="max-width:100px" class="van-ellipsis">{{item.course_cate_id}}</div>
                                        </van-tag>
                                    </div>
                                    <div>
                                        <van-tag round color="#f4f4f4" v-if="item.promise_date_time">诺访:{{item.promise_date_time}}</van-tag>
                                    </div>
                                </div>
                                <!-- <div @click.stop='saveData(item)' class="crm-customer-qywx">
                                    <van-icon class="crm-customer-icon" class-prefix="icon" name="https://i01.wedots.cn/2022/06/20/d91a26201e35220aa147b47aeef0b01f.png" size="25">
                                        <a class="crm-customer-tel"></a>
                                    </van-icon>
                                </div> -->

                                <div @click.stop='saveData(item)' class="crm-customer-phone">
                                    <van-icon class="crm-customer-icon" name="phone-circle-o" size="24" color="#969799">
                                        <a class="crm-customer-tel" :href="'tel:'+item.cellphone"></a>
                                    </van-icon>
                                </div>
                            </div>
                        </template>
                    </van-cell>
                </van-list>
                <van-empty v-if="list.length == 0" description="暂无相关数据~" />
            </div>
            <div 
                class="float-ball"
                ref="ball"
                @touchstart.stop='handleTouchstart'
                @touchmove.stop='handleTouchmove'
                @touchend.stop='handleTouchend'
                @click.stop="showSheet"
                :style="{top:`${moveTop}px`,left:`${moveLeft}px`}"
                >
                <van-icon color="#00cca2" name="more-o" />
            </div>
            
            <van-share-sheet class="crm-customer-sheet" v-model="showShare" @select="onSelect" :title="modaldata.customer_name" :options="shareOptions">
                <template slot="description">
                    <van-tag round color="#f4f4f4">{{modaldata.cellphone}}</van-tag>
                    <van-tag v-if="modaldata.promise_date_time" round color="#f4f4f4">{{modaldata.promise_date_time}}</van-tag>
                </template>
            </van-share-sheet>

            <van-popup v-model="showPicker" round position="bottom">
                <van-picker
                    show-toolbar
                    :columns="columns"
                    @cancel="showPicker = false"
                    @confirm="onConfirm"
                />
            </van-popup>

            <van-popup v-model="showPromise" round position="bottom">
                <van-datetime-picker
                    :value='modaldata.promise_date_time?dayjs(modaldata.promise_date_time).$d:dayjs().$d'
                    type="datetime"
                    title="选择年月日"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm='handlePromiseTime'
                    />
            </van-popup>

            <van-popup v-model="showNextTime" round position="bottom">
                <van-datetime-picker
                    :value='next_time?dayjs(next_time).$d:dayjs().$d'
                    type="datetime"
                    title="选择年月日"
                    :min-date="next_minDate"
                    :max-date="next_maxDate"
                    @confirm='handleNextTime'
                    />
            </van-popup>

            <van-popup v-model="showState" round position="bottom">
                <van-form @submit="onSubmitState">
                    <van-field name="radio" label="跟进状态">
                        <template #input>
                            <van-radio-group :value="task_step" direction="horizontal">
                                <van-radio @click="handleRadioChange(item)" style="margin-bottom:5px" v-for="item in dealStatus" :key='item.value' :name="item.value">{{item.label}}</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-button type="primary" size="large">发布</van-button>
                </van-form>
            </van-popup>

            <van-popup v-model="showLog" round position="bottom">
                <van-form>
                    <van-field name="radio" label="跟进状态">
                        <template #input>
                            <van-radio-group :value="task_step" direction="horizontal">
                                <van-radio @click="handleRadioChange(item)" style="margin-bottom:5px" v-for="item in dealStatus" :key='item.value' :name="item.value">{{item.label}}</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>

                    <van-field
                        readonly
                        clickable
                        :value="next_time"
                        label="回访时间"
                        placeholder="点击选择"
                        @click="showNextTime = true"
                    />

                    <van-collapse v-model="activeNames" accordion>
                        <van-collapse-item title="快捷跟进" name="1">
                            <van-tag round v-for="(item,i) in discourse" :key="i" 
 style="margin-right:10px;">
                                <div style="max-width:100px" class="van-ellipsis" @click="discourseClick(item)">#{{item.discourse_content}}</div>
                            </van-tag>
                        </van-collapse-item>
                    </van-collapse>

                    <van-field
                        v-model="follow_log"
                        rows="4"
                        autosize
                        label="跟进内容"
                        type="textarea"
                        maxlength="500"
                        placeholder="请输入跟进内容"
                        right-icon="https://i01.wedots.cn/2023/05/24/ce151beb0825d70084408999c3980a01.png"
                        @click-right-icon="handleRightClick"
                        show-word-limit
                    />

                    <van-field
                        name="log_images"
                        label="图片上传"
                        >
                        <template #input>
                            <van-uploader :after-read="e=>afterRead(e)" :before-delete='(e,b)=>beforeDelete(e,b)' multiple :max-count="9" v-model="imageListF" />
                        </template>
                    </van-field>

                    <van-button @click="onSubmitLog" :loading="sendLoading" type="primary" size="large">发布</van-button>
                </van-form>
            </van-popup>

            <van-share-sheet class="crm-customer-sheet" v-model="showTransfer" @select="tocheck" title="" :options="transferOptions"></van-share-sheet>

            <div v-if="isCheck">
                <van-submit-bar label='已选：' :price="checkNum.length * 100" :disabled='checkNum.length === 0' currency="" :decimal-length='0' button-text="批量转移" @submit="onSubmit">
                    <van-checkbox @click="handleCheckAll" checked-color="#07c160" v-model="checked">全选</van-checkbox>
                    <!-- <van-button @click="toCancel" style="margin-left:5px" size="small" type="default">取消</van-button> -->
                </van-submit-bar>
            </div>

            <van-popup v-model="showEmployee" round position="bottom">
                <van-picker
                    show-toolbar
                    value-key="employee_name"
                    :columns="employee_list"
                    @cancel="showEmployee = false"
                    @confirm="onConfirmEmployee"
                />
            </van-popup>            

            <van-popup v-model="show" round position="bottom">
            <van-cascader
                v-if="options.length>0"
                v-model="cascaderValue"
                title="请选择诺访时间"
                :options="options"
                active-color="#00DE93"
                @close="show = false"
                @finish="onFinish"
            />
            <van-empty v-else description="暂无相关排课信息~" />
            </van-popup>

            <van-popup v-model="transShow" round position="bottom">
            <van-cascader
                v-if="transOptions.length>0"
                v-model="transCascaderValue"
                title="请选择转移对象"
                :options="transOptions"
                active-color="#00DE93"
                @close="transShow = false"
                @finish="onTransFinish"
            />
            <van-empty v-else description="暂无可以转移对象~" />
            </van-popup>

            <van-popup v-model="filter_show" position="right" :style="{ width: '70%' ,height:'100%'}">
                <van-empty description="暂无搜索信息~" />
            </van-popup>

            <van-button type="primary" block @click="toAdd" class="report-btn">新增客户</van-button>

            <loadings :loading='searchLoading' />
        </div>
    </div>
</template>

<script>
    import {Collapse, CollapseItem, Search , Popup, Picker, Empty, ShareSheet, Tag, Toast, Form, Field, DatetimePicker, RadioGroup, Radio, Checkbox, SubmitBar, Dialog, Uploader, Cascader, DropdownMenu, DropdownItem, Sticky} from 'vant'
    import Avatar from 'vue-avatar'
    import dayjs from 'dayjs'
    import gwm from 'gwm'
    import upload from '@/utils/upload'
    import Loadings from '@/components/loading'
    import { weWorkchatConfig } from '@/common/wx'
    export default {
        name:'crm_customer',
        components: {
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem,
            [Search.name]: Search,
            [Popup.name]: Popup,
            [Picker.name]: Picker,
            [Avatar.name]: Avatar,
            [Empty.name]: Empty,
            [Sticky.name]: Sticky,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Cascader.name]: Cascader,
            [Tag.name]: Tag,
            [Form.name]: Form,
            [Field.name]: Field,
            [Radio.name]: Radio,
            [RadioGroup.name]: RadioGroup,
            [ShareSheet.name]: ShareSheet,
            [DatetimePicker.name]: DatetimePicker,
            loadings:Loadings,
            [Checkbox.name]: Checkbox,
            [SubmitBar.name]: SubmitBar,
            [Uploader.name]: Uploader,
        },
        data() {
            return {
                activeNames: '0',
                discourse: [],
                filter_is_sub: '0',
                filter_task_step: '',
                filter_sort_by: '',
                option1: [
                    { text: '跟进状态', value: '' },
                    { text: '未跟进', value: 0},
                    { text: '跟进中', value: 1},
                    { text: '已预约', value: 2},
                    { text: '已到访', value: 3},
                    { text: '已成单', value: 4},
                    { text: '暂搁', value: 5 },
                    { text: '无效', value: 10},
                    { text: '未接通', value: 6},
                    { text: '长期跟进', value: 15},
                ],
                option2: [
                    { text: '默认排序', value: '' },
                    { text: '最后跟进', value: '-recent_contact' },
                    { text: '分配时间', value: '-assigned_at' },
                    { text: '诺访时间', value: '-promise_date_time' },
                    { text: '回访时间', value: '-next_time' },
                    { text: '添加时间', value: '-created_at' }
                ],
                sub_option:[
                    { text: '我的', value: '0' },
                    { text: '我下属的', value: '1' }
                ],
                loading:false,
                finished: false,
                sendLoading: false,
                searchLoading: false,
                keyword: '',
                list:[],
                listFinishedText: '没有更多了',
                pageSize: 10,
                pageCurrent: 1,
                columns: ['我的','我下属的'],
                showPicker:false,
                showShare:false,
                showPromise:false,
                showState:false,
                showTransfer:false,
                showLog:false,
                showNextTime:false,
                pickerIndex:0,
                modaldata:{},
                shareOptions: [
                    [
                        { name: '手机微信号', icon: 'wechat', id:1 },
                        // { name: '企业微信', icon: 'https://i01.wedots.cn/2022/02/18/40beac9192b1775461855a8722a23ea4.png', id:8 },
                    ],
                    [
                        // { name: '状态变更', icon: require('../../assets/follow.png'), id:2 },
                        { name: '客户跟进', icon: require('../../assets/follow_txt.png'), id:3 },
                        { name: '诺访时间', icon: require('../../assets/promise_date.png'), id:4 },
                        { name: '课程购买', icon: require('../../assets/buy_course.png'), id:5 },
                        { name: '客户转移', icon: require('../../assets/transform.png'), id:6 },
                        { name: '取消诺访', icon: require('../../assets/cancel_audition.png'), id:7 },
                    ],
                ],
                transferOptions: [
                    [
                        { name: '批量转移', icon: require('../../assets/transform.png'), id:1 },
                    ],
                ],
                minDate: new Date(2000, 1, 1),
                maxDate: new Date(9999, 12, 30),
                next_minDate: dayjs().add(1,'day').$d,
                next_maxDate: dayjs().add(30,'day').$d,
                dealStatus: [
                    { label: '未跟进', value: 0 , color:'#1ab394'},
                    { label: '跟进中', value: 1 , color:'#23c6c8'},
                    { label: '已预约', value: 2 , color:'#10aeff'},
                    { label: '已到访', value: 3 , color:'#f8ac59'},
                    { label: '已成单', value: 4 , color:'#f76260'},
                    { label: '暂搁', value: 5 , color:'#ababab'},
                    { label: '无效', value: 10 , color:'#ababab'},
                    { label: '未接通', value: 6 , color:'#ababab'},
                    { label: '长期跟进', value: 15 , color:'#ababab'},
                ],
                follow_log:'',
                next_time:'',
                isVoice:false,
                voice:{
                    localId: '',
                    serverId: ''
                },
                task_step:-1,
                task_step_text:'',
                moveTop:415,
                moveLeft:317,
                isCheck:false,
                checked:false,
                checkNum:[],
                employee_list:[],
                imageList:[],
                imageListF:[],
                showEmployee:false,
                show: false,
                fieldValue: '',
                cascaderValue: '',
                options: [],
                filter_show: false,
                transShow: false,
                transOptions: [],
                transCascaderValue: '',
                isWxWork:false,
            }
        },
        created () {
            if(localStorage.getItem("is_sub")){
                this.filter_is_sub = localStorage.getItem("is_sub")
            }
            // localstorage.setItem("key","value")
            this.toShowShare()
            // this.getEmployee()
            this.getDiscourse()
            this.getAudition()
            this.getTransEmployee()
            let str = window.navigator.userAgent
            if(str.indexOf("wxwork") != -1){
                this.isWxWork=true   
            }else{
                this.getemployeeWatermark()
            }
            if(this.isWxWork){
                weWorkchatConfig()
            }
        },
        methods: {
            dayjs,
            getDiscourse(){
                this.$api.discourse_customer()
                .then(res=>{
                    this.discourse = res.data
                })
            },
            discourseClick(item){
                if(this.follow_log){
                   this.follow_log += '，'+item.discourse_content;
                }else{
                   this.follow_log = item.discourse_content;
                }
                this.$api.discourse_click({id:item.discourse_id}).then(res=>{})
            },
            handleRightClick(){
                var that = this;
                if(that.isVoice){
                    that.isVoice = false;
                    wx.stopRecord({
                        success: function (res) {
                            that.voice.localId = res.localId;
                            wx.translateVoice({
                                localId:that.voice.localId,
                                complete: function (res) {
                                    if (res.hasOwnProperty('translateResult')) {
                                        that.follow_log += res.translateResult
                                    } else {
                                        alert('语音转文字失败！');
                                    }
                                }
                            });
                        },
                        fail: function (res) {
                            alert(JSON.stringify(res));
                        }
                    });
                }else{
                    wx.startRecord({
                        success: function(res) {
                            var tempFilePath = res.tempFilePath 
                            that.isVoice = true;
                        },
                        fail: function(res) {
                            //录音失败
                        }
                    })
                }
            },
            getList(){
                this.$api.crm_customer_index({ 'per-page': this.pageSize, page: this.pageCurrent, is_sub:this.filter_is_sub, search:{keyword:this.keyword,task_step:this.filter_task_step},sort:this.filter_sort_by})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                })
            },
            changeFilter(){
                localStorage.setItem('is_sub', this.filter_is_sub)
                this.loading = true
                this.finished = false
                this.list = []
                this.pageCurrent = 1
                this.getList()
            },
            getAudition(){
                this.$api.crm_customer_audition_schedule({customer_id:this.customer_id})
                .then(res=>{
                    this.options=res.data.cascader_list;
                })
            },
            getTransEmployee(){
                this.$api.crm_filter_get_cascader_employee()
                .then(res=>{
                    this.transOptions=res.data.cascader_list;
                })
            },
            getEmployee(){
                this.$api.employee_subordinate({})
                .then(res=>{
                    this.employee_list = res.data
                })
            },
            onFinish({ selectedOptions }) {
                Dialog.confirm({
                        message: "设定诺访时间为 "+selectedOptions.map((option) => option.text).join('/')
                    }).then(() => {
                            this.$api.crm_customer_audition_arrange({task_id:this.modaldata.task_id,course_class_id:selectedOptions[1].value})
                            .then(res=>{
                                Toast.success('操作成功')
                                this.modaldata.promise_date_time = selectedOptions[1].text.substring(0,16);
                            })
                            .catch(err=>{
                                Toast.error('操作失败')
                            })
                            // this.show = false
                        }).catch(() => {
                            //this.show = false
                            // on cancel
                        });
            },
            getemployeeWatermark(){
                console.log('yes');
                if(localStorage.getItem('employee_watermark')){
                    this.$nextTick(()=>{
                        gwm.creation({
                            container:this.$refs.gwn,
                            txt:localStorage.getItem('employee_watermark'),
                            mode: 'svg',
                            watch: false,
                            fontSize: 11,
                            height:80,
                            x: 20,
                            y: 30,
                            color: '#bbbfca',
                            font: 'sans-serif',
                            alpha: 0.2,
                            angle: -15
                        })
                    })
                }else{
                    this.$api.employee_index().then(res => {
                        localStorage.setItem('employee_watermark',res.data.employee.employee_watermark)
                        this.$nextTick(()=>{
                            gwm.creation({
                                container:this.$refs.gwn,
                                txt:localStorage.getItem('employee_watermark'),
                                mode: 'svg',
                                watch: false,
                                fontSize: 11,
                                height:80,
                                x: 20,
                                y: 30,
                                color: '#bbbfca',
                                font: 'sans-serif',
                                alpha: 0.2,
                                angle: -15
                            })
                        })
                    })
                }
            },
            onLoad() {
                this.getList()
            },
            onConfirm(val,index){
                if(this.pickerIndex != index){
                    this.pickerIndex = index
                    this.loading = true
                    this.finished = false
                    this.list = []
                    this.pageCurrent = 1
                    this.getList()
                }
                this.showPicker = false
            },
            toAdd(){
                this.$router.push({path:'/crm/customer/add'})
            },
            toDetail(item){
                this.$router.push({path:'/crm/customer/detail',query:{task_id:item.task_id,customer_id:item.customer_id}})
            },
            onSelect(option){
                if(option.id === 1){
                    this.$copyText(this.modaldata.cellphone).then(function (e) {
                        Toast.success('手机号复制成功')
                    }, function (e) {
                        Toast.success('复制出错')
                    })
                }
                // if(option.id === 2){
                //     this.showState = true
                //     this.task_step = this.modaldata.task_step
                // }
                if(option.id === 3){
                    this.task_step = this.modaldata.task_step
                    this.next_time = this.modaldata.next_time
                    this.showLog = true
                }
                if(option.id === 4){
                    // this.showPromise = true
                    this.show=true;
                }
                if(option.id === 5){
                    this.$router.push({path:'/teacher/invited/teacherApply',query:{task_id:this.modaldata.task_id}})
                }
                if(option.id === 6){
                    this.checkNum = [this.modaldata.task_id]
                    // this.showEmployee = true
                    this.transShow=true;
                }
                if(option.id === 7){
                    Dialog.confirm({
                        message: "取消当前客户的诺访时间？",
                    })
                        .then(() => {
                            this.$api.crm_customer_audition_cancel({task_id:this.modaldata.task_id})
                            .then(res=>{
                                Toast.success('操作成功')
                                this.showPromise = false
                                this.modaldata.promise_date_time='';
                            })
                            .catch(err=>{
                                Toast.error('操作失败')
                            })
                        })
                        .catch(() => {
                            // on cancel
                        });
                }

            },
            saveData(item){
                this.$api.crm_customer_call({task_id:item.task_id})
                this.modaldata = item
            },
            isShowShare(item){
                this.modaldata = item
                this.showShare = true
            },
            toShowShare(){
                let that = this
                document.addEventListener("visibilitychange", function(){
                    if(!document.hidden){
                        if(that.modaldata.task_id){
                            that.showShare = true
                        }
                    }
                })
            },
            handlePromiseTime(e){
                this.$api.crm_customer_task_update({task_id:this.modaldata.task_id,promise_date_time:dayjs(e).format('YYYY-MM-DD HH:mm')})
                .then(res=>{
                    Toast.success('操作成功')
                    this.showPromise = false
                    this.modaldata.promise_date_time = dayjs(e).format('YYYY-MM-DD HH:mm')
                })
                .catch(err=>{
                    Toast.error('操作失败')
                })
            },
            handleNextTime(e){
                this.next_time = dayjs(e).format('YYYY-MM-DD HH:mm')
                this.showNextTime = false
            },
            handleRadioChange(item){
                this.task_step = item.value
                this.task_step_text = item.label
            },
            onSubmitState(){
                this.$api.crm_customer_task_update({task_id:this.modaldata.task_id,task_step:this.task_step})
                .then(res=>{
                    Toast.success('操作成功')
                    this.modaldata.task_step = this.task_step
                    this.modaldata.task_step_text = this.task_step_text
                })
                .catch(err=>{
                    Toast.error('操作失败')
                })
            },
            onSubmitLog(){
                if(!this.next_time){
                    Toast.success('请选择回访时间～')
                    return false
                }
                if(!this.follow_log){
                    Toast.success('请选择填写跟进内容～')
                    return false
                }
                let obj = {
                    task_id:this.modaldata.task_id,
                    log:this.follow_log,
                    task_step:this.task_step,
                    next_time:this.next_time
                }
                if(this.imageList.length > 0){
                    obj.log_images = this.imageList
                }
                this.sendLoading = true
                this.$api.crm_customer_follow(obj)
                .then(res=>{
                    this.sendLoading = false
                    Toast.success('操作成功')
                    this.follow_log = ''
                    this.showLog = false
                    this.modaldata.task_step = this.task_step
                    this.modaldata.task_step_text = this.task_step_text
                    this.imageList = []
                    this.imageListF = []
                    if(!this.modaldata.task_step){
                        this.modaldata.task_step_text = '跟进中'
                        this.modaldata.task_step = 1
                    }
                })
                .catch(err=>{
                    Toast.error('操作失败')
                    this.sendLoading = false
                })
            },
            handleTouchstart(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchmove(e){
                e.preventDefault()
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchend(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            onSubmit(){
                // this.showEmployee = true
                this.transShow=true
            },
            handleCheck(item){
                let index = this.checkNum.indexOf(item.task_id)
                if(index == -1){
                    this.checkNum.push(item.task_id)
                }else{
                    this.checkNum.splice(index,1)
                }
                if(this.checkNum.length == this.list.length){
                    this.checked = true
                }else{
                    this.checked = false
                }
            },
            handleCheckAll(){
                let arr = []
                if(this.checked){
                    this.list.forEach(item=>{
                        item.checked = true
                        arr.push(item.task_id)
                    })
                    this.checkNum = arr
                }else{
                    this.list.forEach(item=>{
                        item.checked = false
                    })
                    this.checkNum = arr
                    this.checked = false
                    this.isCheck = false
                }
            },
            toCancel(){
                this.list.forEach(item=>{
                    item.checked = false
                })
                this.checkNum = []
                this.checked = false
                this.isCheck = false
            },
            onTransFinish({ selectedOptions }){
                let that = this
                let emplyee_name= selectedOptions.map((option) => option.text).join('/');
                Dialog.confirm({
                title: '提示',
                message: `是否确认转给 ${emplyee_name}，${this.checkNum.length}个客户`,
                })
                .then(() => {
                    that.$api.crm_customer_transfer({task_id:that.checkNum,studio_id:selectedOptions[0].value,employee_id:selectedOptions[1].value})
                    .then(res=>{
                        Toast.success('操作成功')
                        that.checkNum = []
                        that.searchLoading = true
                        that.isCheck = false
                        that.checked = false
                        that.transShow = false
                        that.pageCurrent = 1
                        that.getList()

                    })
                    .catch(err=>{
                        Toast.error('操作失败')
                    })
                })
                .catch(() => {
                    that.showEmployee = false
                });
            },
            onConfirmEmployee(val,index){
                let that = this
                Dialog.confirm({
                title: '提示',
                message: `是否确认转给 ${val.employee_name}，${this.checkNum.length}个客户`,
                })
                .then(() => {
                    that.$api.crm_customer_transfer({task_id:that.checkNum,employee_id:val.employee_id})
                    .then(res=>{
                        Toast.success('操作成功')
                        that.checkNum = []
                        that.searchLoading = true
                        that.isCheck = false
                        that.checked = false
                        that.showEmployee = false
                        that.pageCurrent = 1
                        that.getList()

                    })
                    .catch(err=>{
                        Toast.error('操作失败')
                    })
                })
                .catch(() => {
                    that.showEmployee = false
                });
            },
            showSheet(){
                this.showTransfer = true
            },
            tocheck(){
                this.isCheck = true
                this.showTransfer = false
            },
            afterRead(file) {
                this.$forceUpdate()
                let that = this;
                if(!file.length){
                    upload({ file: file.file })
                    .then(res => {
                        that.imageList.push(res.fileName)
                    })
                    .catch(err => {
                        console.error(err)
                    })
                }else{
                    file.forEach(it=>{
                        upload({ file: it.file })
                            .then(res => {
                                that.imageList.push(res.fileName)
                            })
                            .catch(err => {
                                console.error(err)
                            })
                    })
                }
            },
            beforeDelete(file,detail){
                this.imageList.splice(detail.index,1)
                this.$forceUpdate()
                return true
            },        
        },
        activated () {
            // 如果不是从详情页面返回，则重新加载数据
            if (!this.$route.meta.isBack) {
                this.getemployeeWatermark()
            //加载页面数据
            }
            // 从新设置页面得路由元信息
            this.$route.meta.isBack = false
        },
        beforeRouteLeave(to, from, next) {
            if (to.path === '/crm/customer/detail') { // 去往详情页的时候需要缓存组件，其他情况下不需要缓存
                this.$store.commit('app/updateCatchComponents', ['crm_customer'])
            } else {
                this.$store.commit('app/updateCatchComponents', [])
            }
            next()
        },
        watch: {
            keyword() {
                this.searchLoading = true
                this.pageCurrent = 1
                this.getList()
            }
        },
    }
</script>

<style lang="less">
    .report-btn{
        width: 100%;
        height: 40px;
        background-color: #00DE93;
        position: fixed;
        bottom: 0;
        text-align: center;
        line-height: 50px;
        color: #fff;
    }
    .crm-customer{
        min-height: 100vh;
        .crm-customer-search{
            display: flex;
            align-items: center;
            background: #fff;
            position: relative;
            z-index: 100;
            .crm-customer-sub{
                margin-left: 0.2rem;
                padding: 10px 0;
            }
            .van-search{
                flex:1
            }
        }
        .van-search__action{
            line-height: initial;
        }
        &-info{
            display: flex;
            align-items: center;
            position: relative;
            .vue-avatar--wrapper{
                margin-right: 10px;
            }
            .crm-customer-qywx{
                display: flex;
                align-items: center;
                position: absolute;
                right: 6    0px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                justify-content: center;
                background: rgb(244,244,244);
            }
            .crm-customer-phone{
                display: flex;
                align-items: center;
                position: absolute;
                right: 20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                justify-content: center;
                background: rgb(244,244,244);
            }
        }
        .crm-customer-list{
            .van-cell{
                align-items: center;
                background-color: rgba(0,0,0,0);
            }
            .van-tag--default{
                margin-right: 5px;
                color: #888;
                font-size: 10px;
            }
            .crm-customer-icon{
                position: relative;
                .crm-customer-tel{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }
        .crm-customer-sheet{
            .van-tag--default{
                margin-right: 5px;
                color: #888;
            }
        }
    }
    .float-ball{
        position: fixed;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px #999;
        .van-icon{
            font-size: 0.8rem;
        }
    }
</style>